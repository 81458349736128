<template>
  <div class="homePage sc-width">
    <div class="top">
      <div class="back-request" @click="back()">
        <div class="pic"><img src="@/assets/back.png" alt="" /></div>
        <div class="word">Return to Product</div>
      </div>
    </div>

    <div class="wide">
      <h1 class="main-title title">Request a Sample</h1>
      <Form
        ref="sampleForm"
        :model="sampleForm"
        class="request-form"
        :label="200"
      >
        <h2 class="divide">Sample request</h2>
        <Row :gutter="20">
          <i-col span="12">
            <FormItem prop="product_id" :rules="required" label="Product Name">
              <Input :value="$route.query.pro" disabled />
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem prop="product_id" :rules="required" label="Factory Name">
              <Input :value="$route.query.fac" disabled />
            </FormItem>
          </i-col>

          <i-col span="12">
            <FormItem prop="sample_size" :rules="required" label="Sample Size">
              <Input
                v-model="sampleForm.sample_size"
                placeholder="input sample size"
              />
              <!-- <Select
                v-model="sampleForm.sample_size"
                placeholder="select sample size"
              >
                <Option
                  v-for="(item, index) in sampleInfo.sample_size"
                  :value="item"
                  :key="index"
                  >{{ item }}</Option
                >
              </Select> -->
            </FormItem>
          </i-col>

          <i-col span="12">
            <FormItem prop="purpose" :rules="required" label="Purpose">
              <Input v-model="sampleForm.purpose" placeholder="input purpose" />
              <!-- <Select v-model="sampleForm.purpose" placeholder="select purpose">
                <Option
                  v-for="(item, index) in sampleInfo.purpose"
                  :value="item"
                  :key="index"
                  >{{ item }}</Option
                >
              </Select> -->
            </FormItem>
          </i-col>

          <i-col span="24">
            <FormItem
              prop="delivery_address"
              :rules="required"
              label="Delivery Address"
            >
              <Input
                v-model="sampleForm.delivery_address"
                placeholder="input delivery address"
              />
            </FormItem>
          </i-col>

          <i-col span="24">
            <FormItem label="Documents">
              <Select filterable multiple v-model="sampleForm.documents">
                <Option
                  v-for="item in curProDocumentLists"
                  :value="item.id"
                  :key="item.id"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </i-col>

          <i-col span="24">
            <FormItem label="Remarks">
              <!-- <Editor
                ref="editor"
                formname="conferenceForm"
                @changeDesc="changeDesc"
              /> -->
              <Input
                type="textarea"
                placeholder="input your remark"
                v-model="sampleForm.remark"
              />
            </FormItem>
          </i-col>
        </Row>
        <br />

        <FormItem class="form-btn">
          <div class="btns">
            <div class="cancel-button search-button" @click="back()">
              Cancel
            </div>
            <Button
              :loading="createLoading"
              type="primary"
              @click="createSubmit"
              class="submit-button"
              >Submit</Button
            >
          </div>
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
// import Editor from "@/components/editor";

import api from "@/api";
import { mapState } from "vuex";
const { _queryDocumentList, _createSample, _querySampleRelatedInfo } = api;

export default {
  mounted() {
    _querySampleRelatedInfo()
      .then(response => {
        const { data } = response;
        this.sampleInfo = data[0];
      })
      .catch(error => {
        console.log(error);
      });

    _queryDocumentList({
      limit: 999
    })
      .then(response => {
        const {
          data: { data }
        } = response;

        this.documentList = data;
      })
      .catch(error => {
        console.log(error);
      });

    this.sampleForm.delivery_address = this.company_addr;
  },
  // components: { Editor },
  computed: {
    ...mapState("user", ["company_addr"]),
    percent() {
      return this.form2.composition_percent.reduce((all, next) => {
        return all + Number(next.composition);
      }, 0);
    }
  },

  data() {
    return {
      curProDocumentLists: JSON.parse(
        sessionStorage.getItem("prodcutDocuments")
      ),
      createLoading: false,
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      sampleForm: {
        product_id: this.$route.query.id,
        sample_size: null,
        documents: [],
        purpose: null,
        delivery_address: null,
        remark: null
      },
      documentList: [],
      sampleInfo: {}
    };
  },

  methods: {
    back() {
      this.$router.go(-1);
    },
    changeDesc(desc) {
      this.sampleForm.remark = desc;
    },
    createSubmit() {
      this.$refs.sampleForm.validate(valid => {
        if (valid) {
          this.createLoading = true;
          _createSample({ ...this.sampleForm })
            .then(() => {
              this.$Notice.success({
                title: "Submit successful",
                desc:
                  "You have successfully submitted a sample request to Exhibitor, they will get back soon to you."
              });
              this.$router.push(`/products/${this.$route.query.id}`);
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.homePage {
  background: #ffffff;
  margin: 120px auto 0 auto;
}
.top {
  .back-request {
    width: 220px;
    height: 40px;
    background: #ef7f21;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
    top: 39px;
    .pic {
      width: 8px;
      height: 10px;

      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 8px;
        left: 0px;
      }
    }

    .word {
      position: relative;
      top: -2px;
      font-size: 20px;

      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
    }
  }
}
.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}
.wide {
  margin: 0 auto;
  position: relative;
  top: 70px;
  height: 680px;
  .main-title {
    height: 90px;

    text-align: left;
    background: linear-gradient(90deg, #a25d72, #2d3b81);
    opacity: 0.9;
    border-radius: 5px;
    font-size: 28px;

    font-weight: 700;
    color: #ffffff;
    line-height: 18px;
    padding: 35px 0 0 50px;
  }
  .divide {
    border-bottom: 1px solid #ccc;
    padding-bottom: 40px;
    margin: 40px 0 13px 50px;
    height: 24px;
    font-size: 24px;

    font-weight: 500;
    color: #323538;
    line-height: 18px;
  }

  .item-tips {
    position: absolute;
    right: 0;
    font-size: 12px;
    .info {
      color: #999;
    }
    .link {
      color: @primary-color;
      text-decoration: underline;
    }
  }
  .form-box-group {
    .form-box-title {
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 12px;
      color: #515a6e;
      line-height: 1;
      padding: 10px 12px 10px 0;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    .compose {
      display: inline-block;
      width: 100%;
      border: 1px solid #ccc;
      padding: 10px;
      border-radius: 4px;
      margin-bottom: 6px;
    }
  }

  .form-btn {
    justify-content: center;
    display: flex;
    .btns {
      display: flex;
      .cancel-button {
        width: 113px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #ef8225;
        border-radius: 5px;
        font-size: 18px;

        font-weight: 400;
        color: #ef8225;
        line-height: 18px;
        text-align: center;
        padding-top: 6px;
        margin-right: 20px;
      }
      .submit-button {
        width: 200px;
        height: 34px;
        background: linear-gradient(90deg, #f5a210, #ef8126);
        border-radius: 5px;
        font-size: 18px;

        font-weight: 400;
        color: #ffffff;
        line-height: 18px;
      }
    }
  }

  .upload {
    width: 100%;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 20px;
    cursor: pointer;

    .upload-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .icon {
        color: @primary-color;
      }
    }
  }

  .images-box {
    width: 100%;
    overflow: hidden;
    display: flex;
    .item {
      width: 100px;
      height: 100px;
      background: #eee;
      border-radius: 6px;
      overflow: hidden;
      position: relative;
      margin-right: 10px;
      &:hover {
        .remove {
          opacity: 1;
        }
      }
      .remove {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        cursor: pointer;
        opacity: 0;
      }
      .image {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    .add {
      width: 100px;
      height: 100px;
      background: #eee;
      border-radius: 6px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      .tips {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
    }
  }
}

.cropper {
  width: 100%;
  height: 300px;
  border: solid 1px #ccc;
  margin-bottom: 20px;
}
</style>

<style scoped>
.checkbox-box {
  padding: 0 10px;
}
.checkbox-box-input {
  width: initial;
  .ivu-input {
    border: 1px solid #ccc !important;
  }
}

.ivu-notice-desc {
  text-align: inherit;
}
.ivu-row {
  margin-left: 35px !important;
  margin-right: 35px !important;
}
.request-form >>> .ivu-form-item-label {
  font-size: 16px;

  font-weight: 400;
  color: #292929;
  line-height: 18px;
  width: 200px;
}
.request-form >>> .ivu-form-item-content {
  margin-left: 200px;
}
</style>
